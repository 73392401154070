import React from 'react';
import "../App.css";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Solo = styled.div`
    // gap: 5%;
    margin-top: 7%;
    text-align: start;

    @media only screen and (max-width: 768px) {  
        /* Your CSS Code for this device size */    
        margin-left: 10%;
        margin-right: 10%;
        max-width: 80%;
    }
    @media only screen and (min-width: 768px) {  
        /* Your CSS Code for this device size */    
        margin-left: 25%;
        margin-right: 25%;
        width: 50%;

        .nowrap {
            white-space: nowrap;
        }
    }
`;


const SpaceGrotesk = styled.div`
  font-family: Open Sans;
  font-size: 11px;
  margin-bottom: 5px;
  /* font-weight: 200; */
  line-height: 18px;

`

const Orbitron = styled.div`
  font-family: Orbitron;
  font-size: 24px;
  font-weight: 700;
`

const Hyperlink = styled.a`
  font-family: Open Sans;
  /* color: ${({secondary}) => secondary ? 'black' : 'blue'}; */
  color: blue;
  
  :hover {
    cursor: help;
  }
`

const Nunito = styled(Link)`
  font-family: Open Sans;
  font-size: 1em;
  padding-top: 1px;
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  &::after {
    content: '→';
    position: absolute;
    right: -20px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover::after {
    opacity: 1;
  }
`;

function Blog() {
  return (
    <div className="App">
      <Solo>
        <Orbitron>My thoughts</Orbitron> <br/>
        <Nunito to="/words/open-source-cad">open source cad is silly</Nunito><br/><br/>
        {/* <Nunito to="/words/stop-making-drones">stop making drones</Nunito><br/><br/> */}
        <Nunito to="/words/american-manufacturing-funding-is-broken">american manufacturing funding is broken</Nunito><br/><br/>
        <Nunito to="/words/string-theory-is-a-psyop">string theory is a psyop</Nunito><br/><br/>
        <Nunito to="/words/geccs-convergence-is-upon-us">geccs convergence is upon us</Nunito><br/><br/>
        <Nunito to="/words/oxford-study-citers">oxford study citers is a doom</Nunito><br/><br/>
        <br/>

        <hr/>
<SpaceGrotesk>These thoughts are mine and subject to change. Not financial advice. Not any kind of advice. <Hyperlink style={{fontSize: '11px'}}href="https://www.ashank.xyz">Go home</Hyperlink>.</SpaceGrotesk><br/>
      </Solo>
    </div>
  );
}

export default Blog;
